import styled from '@emotion/styled';
import Link from '../../../components/Link';

export const LayoutFooter = styled.div`
    background-color: ${(props) => props.theme.palette.primary.main};
    padding: ${(props) => props.theme.spacing(2, 0)};
    position: relative;
    z-index: 600;

    @media (min-width: 981px) {
        padding: ${(props) => props.theme.spacing(2)};
    }
`;

export const LayoutFooterHeader = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.white};
    margin: ${(props) => props.theme.spacing(0, 5)};
    padding: ${(props) => props.theme.spacing(2, 0)};
    display: flex;
    flex-direction: column;

    @media (min-width: 981px) {
        margin: ${(props) => props.theme.spacing(0, 'auto')};
        max-width: 1335px;
        flex-direction: row;
        padding-left: ${(props) => props.theme.spacing(2)}; 
    }
    @media (min-width: 1350px) {
        max-width: 1350px;
    }
`;

export const LayoutFooterHeaderLogos = styled.div`
    @media (min-width: 981px) {
        padding-right: ${(props) => props.theme.spacing(5)};
    }
`;

export const LayoutFooterHeaderText = styled.p`
    ${(props) => props.theme.typography.body2};
    color: ${(props) => props.theme.white};
    margin: ${(props) => props.theme.spacing(0)};
    padding-top: ${(props) => props.theme.spacing(1)};
`;

export const FooterHeaderLink = styled.a`
    font-weight: bold;
    color: ${(props) => props.theme.palette.primary.contrastText};
`;

export const LayoutFooterInner = styled.div`
    margin: ${(props) => props.theme.spacing(0, 'auto')};
    padding: ${(props) => props.theme.spacing(5)};
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: 981px) {
        padding: ${(props) => props.theme.spacing(5, 0)};
        grid-template-columns: repeat(4, 1fr);
        max-width: 1335px;
    }
    @media (min-width: 1350px) {
        max-width: 1350px;
    }
    
    @media (max-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const LayoutFooterColumn = styled.div`
    color: white;
    flex-grow: 1;
    @media (max-width: 981px) {
        &:nth-of-type(2) {
            grid-area: 2 / 1 / 3 / 2;
        }
        &:last-of-type {
            grid-area: 1 / 3 / 3 / 4;
        }
    }
    @media (max-width: 500px) {
        &:nth-of-type(2) {
            grid-area: 2 / 1 / 3 / 2;
        }
        &:nth-of-type(3) {
            grid-area: 1 / 2 / 2 / 3;
        }
        &:last-of-type {
            grid-area: 2 / 2 / 3 / 3;
        }
    }
`;

export const LayoutFooterTitle = styled.h5`
    ${(props) => props.theme.typography.subtitle1};
    margin: ${(props) => props.theme.spacing(0, 0, 2, 0)};
`;

export const LayoutFooterColumnContainer = styled.ul`
    padding: ${(props) => props.theme.spacing(0)};
    margin-top: ${(props) => props.theme.spacing(0)};
    list-style: none;
`;

export const LayoutFooterColumnList = styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const LayoutFooterLink = styled(Link)`
    color: white;
    text-decoration: none;
    display: flex;
    &:hover {
        text-decoration: underline;
    }
`;

export const StyledFooterSocialIcon = styled.div`
    margin-right: ${(props) => props.theme.spacing(2)};
`;

export const LayoutFooterLinkText = styled.p<{ hasIcon: boolean }>`
    margin: ${(props) => props.theme.spacing(0, 0, 2, 0)};
    line-height: ${(props) => props.hasIcon && '20px'};
    ${(props) => props.theme.typography.body2};
`;

export const LayoutCopyright = styled.div<{bottomSheetActive: boolean}>`
    background-color: ${(props) => props.theme.palette.primary.dark};
    padding: ${(props) => props.theme.spacing(5)};
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    z-index: 610;

    padding-bottom: ${(props) => props.bottomSheetActive ? '100px' : props.theme.spacing(5)};

    @media (min-width: 1400px) {
        padding-bottom: ${(props) => props.bottomSheetActive ? '150px' : props.theme.spacing(5)};
    }
`;

export const LayoutCopyrightText = styled.p`
    color: ${(props) => props.theme.white};
    ${(props) => props.theme.typography.caption};
    margin: ${(props) => props.theme.spacing(1, 0)};
`;
