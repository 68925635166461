import { MouseEvent, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../client';
import useTranslation from '../../../hooks/useTranslation';
import getFooterItems from './items';
import {
    LayoutCopyright,
    LayoutCopyrightText,
    LayoutFooter,
    LayoutFooterHeader,
    LayoutFooterHeaderText,
    LayoutFooterHeaderLogos,
    LayoutFooterInner,
    LayoutFooterColumn,
    LayoutFooterTitle,
    LayoutFooterLink,
    LayoutFooterColumnList,
    LayoutFooterColumnContainer,
    LayoutFooterLinkText,
    StyledFooterSocialIcon,
    FooterHeaderLink
} from './styles';
import { recordVirtualPageView } from '@thestudentroom/datalayer';
import { runtimeConfig } from '../../../runtimeConfig';

type FooterLink = {
    key: string;
    title?: string;
    url: string;
    icon?: JSX.Element | null;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
};

type FooterColumnProps = {
    key: string;
    title?: string;
    url?: string;
    children: Array<FooterLink>;
};

function FooterColumn(props: FooterColumnProps) {
    const { t } = useTranslation();

    return (
        <LayoutFooterColumn>
            <LayoutFooterTitle>{props.title}</LayoutFooterTitle>
            <LayoutFooterColumnContainer>
                {props.children.map((value) => (
                    <LayoutFooterColumnList key={value.key}>
                        <LayoutFooterLink
                            url={value.url}
                            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                recordVirtualPageView(
                                    `/virtual/footer/footer-nav/${value.key}?action=click`
                                );

                                // If the link has a click event, trigger that too
                                if (value.onClick) {
                                    value.onClick(e);
                                }
                            }}
                        >
                            {value.icon && (
                                <StyledFooterSocialIcon>{value.icon}</StyledFooterSocialIcon>
                            )}
                            <LayoutFooterLinkText hasIcon={value.icon !== null}>
                                {t(`footer.${value.key}`)}
                            </LayoutFooterLinkText>
                        </LayoutFooterLink>
                    </LayoutFooterColumnList>
                ))}
            </LayoutFooterColumnContainer>
        </LayoutFooterColumn>
    );
}

export default function Footer() {
    const bottomSheetActive = useSelector((state: RootState) => state.adverts.bottomSheetActive);
    const { t } = useTranslation();
    const year = new Date().getFullYear();
    const footerItems = getFooterItems();

    return (
        <footer>
            <LayoutFooter>
                <LayoutFooterHeader>
                    <LayoutFooterHeaderLogos>
                        <img
                            src={runtimeConfig.publicDomain + '/theme/tsr/images/logos/group-logo-block-colour.svg'}
                            alt="The Student Room Group"
                            width="196px" height="31px"
                        />
                    </LayoutFooterHeaderLogos>
                    <LayoutFooterHeaderText data-testid="footer-title">
                        {t('footer.title1')}
                        <FooterHeaderLink href="https://www.theuniguide.co.uk/">
                            {'The Uni Guide'}
                        </FooterHeaderLink>
                        {t('footer.title2')}
                    </LayoutFooterHeaderText>
                </LayoutFooterHeader>
                <LayoutFooterInner>
                    {footerItems.map((item) => (
                        <FooterColumn
                            key={item.key}
                            title={t(`footer.${item.key}`)}
                            children={item.children}
                        />
                    ))}
                </LayoutFooterInner>
            </LayoutFooter>
            <LayoutCopyright bottomSheetActive={bottomSheetActive}>
                <LayoutCopyrightText>
                    &copy; {t('copyright.copyrightyear', { year })}
                </LayoutCopyrightText>
                <LayoutCopyrightText>{t('copyright.copyrighttext1')}</LayoutCopyrightText>
                <LayoutCopyrightText>{t('copyright.copyrighttext2')}</LayoutCopyrightText>
            </LayoutCopyright>
        </footer>
    );
}
