import { MouseEvent } from 'react';
import { FaTwitter, FaFacebookF, FaYoutube, FaInstagram, FaTiktok } from 'react-icons/fa';

export default function getFooterItems() {
    return [
        {
            key: 'get-started',
            children: [
                {
                    key: 'todays-posts',
                    url: '/search.php?filter[type]=thread&sortby=date+desc&filter[date]=[NOW-1DAY+TO+*]',
                    icon: null
                },
                {
                    key: 'unanswered-posts',
                    url: '/search.php?filter[type]=thread&sortby=date+desc&filter[replyCount]=0&filter[date]=[NOW-168HOUR+TO+*]',
                    icon: null
                },
                {
                    key: 'community-guidelines',
                    url: '/help/the-student-room-community-guidelines',
                    icon: null
                }
            ]
        },
        {
            key: 'using-tsr',
            children: [
                {
                    key: 'tsr-help-centre',
                    url: '/help/',
                    icon: null
                },
                {
                    key: 'cookies-online-safety',
                    url: '/help/the-student-room-group-cookie-policy',
                    icon: null
                },
                {
                    key: 'terms-conditions',
                    url: '/help/terms-and-conditions',
                    icon: null
                },
                {
                    key: 'privacy-policy',
                    url: '/help/the-student-room-group-privacy-notice',
                    icon: null
                },
                {
                    key: 'download-app',
                    url: '/help/download-the-student-room-mobile-app/',
                    icon: null
                },
                {
                    key: 'ad-privacy',
                    url: '',
                    icon: null,
                    onClick: (e: MouseEvent) => {
                        if (typeof window !== undefined) {
                            window.__tcfapi('displayConsentUi', 2, function () {});
                        }
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            ]
        },
        {
            key: 'info',
            children: [
                {
                    key: 'about-tsr',
                    url: '/help/about-the-student-room',
                    icon: null
                },
                {
                    key: 'advertise',
                    url: 'https://tsrmatters.com/product/',
                    icon: null
                },
                {
                    key: 'contact-us',
                    url: '/help/contact-us',
                    icon: null
                }
            ]
        },
        {
            key: 'connect-with-tsr',
            children: [
                {
                    key: 'twitter',
                    url: 'https://twitter.com/thestudentroom',
                    icon: <FaTwitter size={20} data-testid="social-icon" />
                },
                {
                    key: 'facebook',
                    url: 'https://www.facebook.com/TheStudentRoom',
                    icon: <FaFacebookF size={20} data-testid="social-icon" />
                },
                {
                    key: 'youtube',
                    url: 'https://www.youtube.com/user/thestudentroom',
                    icon: <FaYoutube size={20} data-testid="social-icon" />
                },
                {
                    key: 'instagram',
                    url: 'https://www.instagram.com/thestudentroom/',
                    icon: <FaInstagram size={20} data-testid="social-icon" />
                },
                {
                    key: 'tiktok',
                    url: 'https://www.tiktok.com/@thestudentroom',
                    icon: <FaTiktok size={20} data-testid="social-icon" />
                }
            ]
        }
    ];
}
